/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

 (function($) {

  function form_submit_button( $button, $form ) {
    return "<button class='button' id='gform_submit_button_{$form[1]}'><span>Test</span></button>";
  }

  const desktop = window.matchMedia('(min-width: 64.0625rem)');
  tablet_small = window.matchMedia('(min-width: 37.5rem)');

  $body = $(document.body),
  $window = $(window);

  function breakpoint() {
    if (!desktop.matches && !tablet_small.matches) {
      return 'mobile';
    } else if (!desktop.matches) {
      return 'tablet';
    } else {
      return 'desktop';
    }
  }

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        // init controller
        // var controller = new ScrollMagic.Controller();
        // // Parallax with ScrollMagic for header image
        // // Scene for header image
        // var slideParallaxScene = new ScrollMagic.Scene({
        //   triggerElement: "#header-background",
        //   triggerHook: 0,
        //   duration: function(){
        //     return $('#header-background').outerHeight();
        //   }
        // })
        // .setTween(TweenMax.to('#home-header', 2, {y: '-15%'}, 0))
        // .addIndicators()
        // .addTo(controller);
        //
        // // Scene for header container
        // var slideParallaxScene = new ScrollMagic.Scene({
        //   triggerElement: "#header-background",
        //   triggerHook: 0,
        //   duration: '30%'
        // })
        // .setTween(TweenMax.to('#header-logo', 2, {autoAlpha: 0}, 0))
        // .addIndicators()
        // .addTo(controller);

        // mobileOS = kota.mobileOS();

        // Add class if Android, iOS or Windows Phone device.
        // if (mobileOS) {
        //   $body.addClass(mobileOS.replace(/\s+/g, '-').toLowerCase());
        // }

        // viewportUnitsBuggyfill is used to convert vh units on iOS and Android to px, this is so backgrond images and height based positioning does't change when scrolling and the viewport height increases.
        viewportUnitsBuggyfill.init({refreshDebounceWait: 250});

        // Fastclick is used to disable 300ms delay on touch devices.
        // FastClick.attach(document.body);

        // Picturefill is used to make <picture> responsive images work on ie11.
        picturefill();

        // WebFont.load({
        //   classes: false,
        //   custom: {
        //     families: ['Cheltenham']
        //   },
        //   google: {
        //     families: ['Open Sans:100,300,400,500,700,900']
        //   },
        //   active: function() {
        //     console.log('webfonts loaded');
        //   }
        // });

        imagesLoaded(document.body, function() {
          console.log('all images are loaded');
        });

        $window.resize(function() {
          console.log(breakpoint());
        });

        $('#menu-toggle').on('click', function() {
          $body.toggleClass('menu-open');
        });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        if (breakpoint() !== 'mobile') {
          $('#slider ul').slick({
            arrows: false,
            dots: true,
            customPaging : function(slider, i) {
              const background = $($(slider.$slides[0]).find(".data-div")).data("background");
              console.log()
              $("#background_image").css("background-image", "url(" + background + ")");
              var thumb = $(slider.$slides[i]).find(".data-div").data('thumb');
              return '<button><span>'+thumb+'</span></button>';
            }
          });

          $('#slider ul').on('beforeChange', function(event, slick, currentSlide, nextSlide){
            const background = $($(slick.$slides[nextSlide]).find(".data-div")).data("background");
            $("#background_image").css("background-image", "url(" + background + ")");
          });

        }

        if (breakpoint() === 'mobile') {
          $('#slide > h3').each(function() {
              var html = $(this).html().split(" ");
              html = html.slice(0, -1).join(" ") + " <br />" + html.pop();
              $(this).html(html);
          });
          $('#background_image > style').remove();
        }


        $window.resize(function() {
          console.log('resize triggered');
          if (breakpoint() !== 'mobile') {
            if (!$('#slider ul').hasClass('slick-initialized')) {
              $('#slider ul').slick({
                arrows: false,
                dots: true,
              });
            }
          }
          else {
            $('#slider ul').slick('unslick');
          }
        });

        // document.getElementsByClassName("slider-title").value =
        // (title + "\n" + address + "\n" + address2 + "\n" + address3 + "\n" + address4);
        // JavaScript to be fired on the home page
      }
    },
    'locations': {
      init: function() {
        $('.map a').click(function(e) {
          $('.map path').removeClass("selected");
          $(this).find('path').addClass("selected");

          let region = $(this).data("name");
          $("#region").html(region);
        })

        $('.content span').click(function(e) {
           let region = $(this).data("name");
          $('.map path').removeClass("selected");
          $('.map').find(`a[data-name="${region}"] path`).addClass("selected");
          console.log(`a[data-name="${region}"]`);

          $("#region").html(region);
        })
      }
    },

    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
    'projects': {
      init: function() {
        // JavaScript to be fired on the about us page
        MicroModal.init();
        $('#projects-wrapper ul li > div').click(function(e) {
          const element = $(this);
          const title = element.data('title');
          const image = element.data('image');

          $('.modal__title').html(title);
          $('.modal__image').css('background-image', 'url(' + image + ')');

          MicroModal.show('modal-project');
        })
        
      }
    },
    'team': {
      init: function() {
         MicroModal.init();
        $('.team-grid .item').click(function(e) {
            const element = $(this);
            const name = element.data('name');
            const image = element.data('image');
            const content = element.data('content');
            const position = element.data('position');

            $('.modal__name').html(name);
            $('.modal__image').css('background-image', 'url(' + image + ')');
            $('.modal__position').html("-" + position);
            $('.modal__copy').html(content);

            MicroModal.show('modal-team');
        })
          
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.


//Slider acordeon on mobile
$(document).ready(function(){
  $('.slide-0 h3').click(function(){
    console.log('clicked');
    $('.slide-0 p').slideToggle();
    $('.slide-0 h3').toggleClass('rotated');
  })
  $('.slide-1 h3').click(function(){
    console.log('clicked');
    $('.slide-1 p').slideToggle();
    $('.slide-1 h3').toggleClass('rotated');
  })
  $('.slide-2 h3').click(function(){
    console.log('clicked');
    $('.slide-2 p').slideToggle();
    $('.slide-2 h3').toggleClass('rotated');
  })

// jQuery - put br after first word of projects
  $('#project-wrapper > h2').each(function() {
    var html = $(this).html().split(" ");
    html = html.slice(0, -1).join(" ") + " <br />" + html.pop();
    $(this).html(html);
  });

});
